import React from "react";
import { ReactController } from "../react_controller";

import { AttendeeScanner } from "../../scanner_components/AttendeeScanner";

export class AttendeeScannerController extends ReactController {
  connect() {
    this.root.render(<AttendeeScanner {...this.propsValue} />);
  }
}
