import React, { FC, useState } from "react";
import { Result } from "@zxing/library";
import {
  Checkpoint,
  Message,
  TicketScanResponse,
  TicketScanResult,
} from "../../domain/checkpoints/types";
import { QRCodeReader } from "@getregistered/greg-editors";
import railsFetch from "../../components/railsFetch";
import { AttendeeCheckpoints } from "./AttendeeCheckpoints";
import "./scanner.css";
import classNames from "classnames";

export interface AttendeeScannerProps {
  ticketCheckUrl: string;
  checkpoint?: Checkpoint;
}

export const AttendeeScanner: FC<AttendeeScannerProps> = ({
  ticketCheckUrl,
  checkpoint,
}) => {
  const [result, setResult] = useState<TicketScanResult | null>(null);
  const [message, setMessage] = useState<Message | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const setError = (text: string) => setMessage({ type: "error", text });
  const setSuccess = (text: string) => setMessage({ type: "success", text });

  const handleCodeScan = async (code: Result) => {
    setLoading(true);
    setMessage(null);
    const response = await railsFetch(ticketCheckUrl, {
      method: "post",
      body: JSON.stringify({ id: code.getText() }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    switch (response.status) {
      case 200:
      case 201:
        const result = (await response.json()) as TicketScanResponse;
        if ("error" in result) {
          setError(result.error);
        } else {
          setResult(result);
        }
        break;
      case 404:
        setError("Ticket not found");
        break;
      default:
        setError("Error fetching ticket");
    }
    setLoading(false);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="test-attendee-scanner-component">
      {message !== null && (
        <p className={classNames("gr-scanner-message", message.type)}>
          {message.text}
        </p>
      )}
      {result !== null ? (
        <AttendeeCheckpoints
          {...result}
          checkpoint={checkpoint}
          onComplete={(message) => {
            setSuccess(message);
            setResult(null);
          }}
          onBack={() => setResult(null)}
        />
      ) : (
        <QRCodeReader onCodeScan={handleCodeScan} />
      )}
    </div>
  );
};
