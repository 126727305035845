import React, { FC, useState } from "react";
import { toggleCheckin } from "../../domain/checkpoints/toggleCheckin";
import { Attendee, Checkin, Checkpoint } from "../../domain/checkpoints/types";

export interface CheckinStatusProps {
  attendee: Attendee;
  checkins: Checkin[];
  checkpoint: Checkpoint;
  onComplete?: (message: string) => void;
}

const getSelectedCheckin = (
  checkpoint: Checkpoint,
  checkins: Checkin[]
): Checkin | undefined =>
  checkins.find((c) => c.checkpointId === checkpoint.id);

export const CheckinStatus: FC<CheckinStatusProps> = ({
  attendee,
  checkins,
  checkpoint,
  onComplete,
}) => {
  const [attendeeCheckin, setAttendeeChekin] = useState<Checkin | undefined>(
    getSelectedCheckin(checkpoint, checkins)
  );

  const handleToggleCheckin = async () => {
    if (attendeeCheckin === undefined) {
      return;
    }

    await toggleCheckin(attendeeCheckin);
    setAttendeeChekin({
      ...attendeeCheckin,
      arrived: !attendeeCheckin.arrived,
    });
    onComplete?.(
      `${attendee.fullName} checked ${attendeeCheckin.arrived ? "out" : "in"}`
    );
  };

  if (attendeeCheckin === undefined) {
    return (
      <p className="gr-scanner-message">
        Attendee <b>NOT VALID</b> for this checkpoint.
      </p>
    );
  }
  if (attendeeCheckin.arrived) {
    return (
      <>
        <p className="gr-scanner-message">Attendee checked in</p>
        <button onClick={handleToggleCheckin} className="full danger">
          Check attendee OUT
        </button>
      </>
    );
  } else {
    return (
      <>
        <p className="gr-scanner-message">
          Attendee <b>NOT</b> checked in
        </p>
        <p>
          <button onClick={handleToggleCheckin} className="full action">
            Check attendee IN
          </button>
        </p>
      </>
    );
  }
};
