import React, { FC } from "react";
import { Attendee, Checkin, Checkpoint } from "../../domain/checkpoints/types";
import { CheckinStatus } from "./CheckinStatus";
import { gridColumns } from "./attendeeCheckinGrid";
import { SimpleGrid } from "@getregistered/greg-editors";
import { renderCheckinCell } from "../../components/grids/CheckinCell/renderCheckinCell";

export interface AttendeeCheckpointsProps {
  attendee: Attendee;
  checkins: Checkin[];
  checkpoint?: Checkpoint;
  onBack: () => void;
  onComplete?: (message: string) => void;
}

export const AttendeeCheckpoints: FC<AttendeeCheckpointsProps> = ({
  attendee,
  checkins,
  checkpoint,
  onBack,
  onComplete,
}) => {
  const gridCheckins =
    checkpoint === undefined
      ? checkins
      : checkins.filter((c) => c.checkpointId !== checkpoint.id);
  return (
    <div>
      <header>
        <div className="fr mb-4">
          <button onClick={onBack} className="common Button Button--outline Button--block">
            Back to Scanner
          </button>
        </div>
        <div className="mb-4">
          <strong>Scanned: {attendee.fullName}</strong> - {attendee.email}
        </div>
      </header>
      {checkpoint !== undefined && (
        <>
          <CheckinStatus
            checkpoint={checkpoint}
            attendee={attendee}
            checkins={checkins}
            onComplete={onComplete}
          />
          <hr></hr>
          <p>Other attendee checkpoints</p>
        </>
      )}
      <SimpleGrid
        columns={gridColumns}
        rows={gridCheckins.map((item) => ({ ...item, checkIn: item }))}
        renderCell={renderCheckinCell}
      />
    </div>
  );
};
