import { GridColumn } from "@getregistered/greg-editors";

export const gridColumns: GridColumn[] = [
  {
    key: "name",
    label: "Checkpoint",
  },
  {
    key: "arrived",
    label: "Checked in?",
    boolean: true,
    trueValue: "Yes",
    falseValue: "No",
  },
  {
    key: "checkIn",
    label: "Check in?",
    type: "checkpoint",
    noSort: true,
    noFilter: true,
  },
];
